import { theme } from '@/src/stitches.config'
import HeadlineEyebrow from '@/src/common/components/marketing/headlines/HeadlineEyebrow'
import {
    LoginProvider,
    useLoginContext,
} from '@/src/modules/login/LoginContext'
import { useEffect } from 'react'
import {
    EMAIL_TOGGLE,
    EXISTING_LOGIN,
} from '@/src/modules/login/LoginConstants'
import { useUser } from '@/src/modules/auth/context/AuthProvider'
import { useRouter } from 'next/router'
import { ClipLoader } from 'react-spinners'
import {
    ACCOUNT_PAGE,
    GIFTS_RECEIVED_PAGE,
    ORDERS_PAGE,
    REFERRALS_PAGE,
} from '@/src/modules/account/received/constants'
import ImageAnimation from './ImageAnimation'
import { CHECKOUT_PATH } from '@/src/modules/pg-plus/constants'
import { LoadingWrapper } from '@/src/modules/pg-plus/PGPlusStyledComponents'
import { StyledH1 } from '@/src/common/components/elements/StyledComponents'
import LoginFormWithCustomLeftContent from '@/src/common/components/elements/Forms/LoginFormWithCustomLeftContent'
import toggleChatBot from '@/src/common/utilities/toggleChatBot'

function redirect(router: any, sentFrom: string, isAdaChat: boolean) {
    let flow = sentFrom?.includes('brands/') ? 'brand' : sentFrom
    let path

    switch (flow) {
        case 'brand':
            path = `/${sentFrom}`
            break
        case 'orders':
            path = ORDERS_PAGE
            break
        case 'checkout':
            path = CHECKOUT_PATH
            break

        case 'gifts-received':
            path = GIFTS_RECEIVED_PAGE
            break
        case 'referrals':
            path = REFERRALS_PAGE
            break
        default:
            path = ACCOUNT_PAGE
            break
    }

    // check if the ada chatbot is already open
    // if it is, do not toggle
    const isChatOpen = document
        .getElementById('ada-chat-frame')
        ?.getAttribute('aria-expanded')

    router.replace(path).then(() => {
        if (isAdaChat && !isChatOpen) {
            toggleChatBot()
        }
    })
}

const LoginPage = () => {
    const {
        loginType,
        emailPhoneToggle,
        actionText,
        setPhoneVerificationText,
        sentFrom,
        isAdaChat,
    } = useLoginContext()

    const { isLoggedIn, isLoaded, isVerified } = useUser()
    const router = useRouter()

    // handles where the user returns to after login / sign up
    useEffect(() => {
        if (isLoggedIn && isVerified) {
            redirect(router, sentFrom, isAdaChat)
        }
    }, [isAdaChat, isLoggedIn, isVerified, router, sentFrom])

    // CTA button text when entering phone verification code
    // needs to be different here in the login page
    useEffect(() => {
        setPhoneVerificationText(actionText)
    }, [actionText, setPhoneVerificationText])

    if (!isLoaded) {
        return (
            <LoadingWrapper>
                <ClipLoader size={50} data-testid={'loader'} />
            </LoadingWrapper>
        )
    }

    return (
        <LoginFormWithCustomLeftContent showCorporateLogin={true}>
            <HeadlineEyebrow
                label={
                    loginType === EXISTING_LOGIN
                        ? 'Welcome Back!'
                        : 'Get Started'
                }
                style={{ marginTop: 15 }}
            />
            <StyledH1 size={'smaller'}>
                A <span className={'gradient'}>Perfect Gift</span>
                <br />
                Every. Single. Time.
            </StyledH1>
            <p
                style={{
                    fontSize: '24px',
                    fontWeight: '400',
                    color: `${theme.colors.dark}`,
                }}
            >
                &quot;Such a quick and convenient way to send a gift that gives
                the recipient options on how they prefer to use it. Love that I
                can add a photo to it!&quot;
            </p>
            <p
                style={{
                    fontSize: '18px',
                    fontWeight: '700',
                    color: `${theme.colors.darkest}`,
                }}
            >
                -PerfectGift.com Customer
            </p>

            <br />
            <ImageAnimation scene={emailPhoneToggle === EMAIL_TOGGLE ? 1 : 2} />
        </LoginFormWithCustomLeftContent>
    )
}

export default function Page() {
    return (
        <LoginProvider>
            <LoginPage />
        </LoginProvider>
    )
}

export async function getServerSideProps() {
    return {
        props: {
            content: {
                data: {
                    title: 'Log in',
                    description:
                        'Member log in for PerfectGift.com. Manage your account, view your received gifts, and experience all PerfectGift.com has to offer.',
                },
            },
        },
    }
}
