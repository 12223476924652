import PurchaseFlowMaxAlert from '@/src/common/components/elements/Alerts/PurchaseFlowMaxAlert'
import usePostUpsertUser from '@/src/common/hooks/usePostUpsertUser'
import { useUser } from '@/src/modules/auth/context/AuthProvider'
import { useEffect, useRef } from 'react'
import EmailPassword from './EmailPassword'
import FacebookButton from './FacebookButton'
import { facebookSignIn, googleSignIn, appleSignIn } from './FirebaseAuth'
import GoogleButton from './GoogleButton'
import AppleButton from './AppleButton'
import {
    EMAIL_TOGGLE,
    EXISTING_LOGIN,
    NEW_LOGIN,
    PHONE_TOGGLE,
} from './LoginConstants'
import { useLoginContext } from './LoginContext'
import PhoneNumber from './PhoneNumber'
import {
    Container,
    CorporateLogin,
    Disclosure,
    Header,
    Line,
    PhoneAndEmailButtons,
    Separator,
    SocialButtons,
    StyledH6,
    ToggleButton,
} from './StyledComponents'
import OverLimitsAlertLogin from '@/src/common/components/elements/Alerts/OverLimitsAlertLogin'
import AlertWithIcon from '@/src/common/components/elements/Alerts/AlertWithIcon'
import { useCookies } from 'react-cookie'
import { APP_TYPE, IOS_PWA_APP_TYPE } from '@/src/common/constants'

const LoginForm = ({
    showCorporateLogin = false,
}: {
    showCorporateLogin: boolean
}) => {
    const {
        actionText,
        loginType,
        setLoginType,
        emailPhoneToggle,
        setEmailPhoneToggle,
        showVerificationScreen,
        setShowVerificationScreen,
        requiresKYC,
        isOverLimits,
        isAdaChat,
    } = useLoginContext()

    const { user, refreshUser, isVerified } = useUser()
    const { mutate: upsertUser } = usePostUpsertUser()
    const timer = useRef<any>()

    const [cookies] = useCookies()
    const appType = cookies.app_type

    useEffect(() => {
        if (isVerified) {
            upsertUser(null, {
                onSuccess: () => {},
                onError: (e) => console.log(e),
            })
        }

        if (user?.email && !user.emailVerified) {
            setEmailPhoneToggle(EMAIL_TOGGLE)
            setShowVerificationScreen(true)

            // check if user verifies email on a different device
            // while on the "we sent verification email" screen
            timer.current = setInterval(() => refreshUser(), 5000)
        }

        if (!user) {
            setEmailPhoneToggle(PHONE_TOGGLE)
            setShowVerificationScreen(false)
            clearInterval(timer.current)
        }

        return () => clearInterval(timer.current)
    }, [user])

    return (
        <Container>
            {requiresKYC && (
                <PurchaseFlowMaxAlert
                    isLogin={true}
                    style={{ marginBottom: 30 }}
                />
            )}
            {isOverLimits && (
                <OverLimitsAlertLogin
                    isLogin={true}
                    style={{ marginBottom: 30 }}
                />
            )}
            {isAdaChat && (
                <AlertWithIcon iconName={'warning'} size={'sm'}>
                    <p>
                        Your chat session will resume automatically after login.
                    </p>
                </AlertWithIcon>
            )}
            <Header>{actionText}</Header>
            {!showVerificationScreen && (
                <>
                    {/*if the app type is an iOS PWA then hide the social login buttons*/}
                    {/*until we figure out to get it working in the iOS App Store app*/}
                    { appType !== IOS_PWA_APP_TYPE && (
                        <>
                            <SocialButtons>
                                <FacebookButton
                                    label={`${actionText} with Facebook`}
                                    onClick={facebookSignIn}
                                />
                                <GoogleButton
                                    label={`${actionText} with Google`}
                                    onClick={googleSignIn}
                                />
                                <AppleButton
                                    label={`${actionText} with Apple`}
                                    onClick={appleSignIn}
                                />
                            </SocialButtons>
                            <Separator>
                                <Line />
                                OR
                                <Line />
                            </Separator>
                        </>
                    )}
                    <PhoneAndEmailButtons>
                        <ToggleButton
                            shortLabel={`${actionText} via phone`}
                            longLabel={`${actionText} via phone`}
                            active={emailPhoneToggle === PHONE_TOGGLE}
                            onClick={() => setEmailPhoneToggle(PHONE_TOGGLE)}
                        />
                        <ToggleButton
                            shortLabel={`${actionText} via email`}
                            longLabel={`${actionText} via email`}
                            active={emailPhoneToggle === EMAIL_TOGGLE}
                            onClick={() => setEmailPhoneToggle(EMAIL_TOGGLE)}
                        />
                    </PhoneAndEmailButtons>
                </>
            )}
            {emailPhoneToggle === EMAIL_TOGGLE ? (
                <EmailPassword />
            ) : (
                <PhoneNumber />
            )}
            {!showVerificationScreen && loginType === NEW_LOGIN && (
                <>
                    <StyledH6>
                        Already have an account?{' '}
                        <button onClick={() => setLoginType(EXISTING_LOGIN)}>
                            Log In
                        </button>
                    </StyledH6>
                    {showCorporateLogin && <CorporateLogin />}
                </>
            )}
            {loginType === EXISTING_LOGIN && (
                <>
                    <StyledH6>
                        Don&apos;t have an account?{' '}
                        <button onClick={() => setLoginType(NEW_LOGIN)}>
                            {' '}
                            Sign Up
                        </button>
                    </StyledH6>
                    {showCorporateLogin && <CorporateLogin />}
                </>
            )}

            {!showVerificationScreen ? <Disclosure /> : ''}
        </Container>
    )
}

export default LoginForm
