import { styled } from '../../../../stitches.config'

export const FormErrorMessage = styled('div', {
    all: 'unset',
    mt: 5,
    padding: '0 10px',
    height: 'auto',
    fontSize: '15px',
    color: '$error',
    display: 'block',
    lineHeight: '20px',
})
