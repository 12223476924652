import { JSXElementConstructor } from 'react'
import { ValidationError } from 'yup'
import { FormErrorMessage } from '../../common/components/elements/Forms/FormErrorMessage'

export interface IFormErrorDisplay {
    formErrors: ValidationError[]
    path: string
    className?: string
    style?: object
}

export const FormErrorDisplay: JSXElementConstructor<IFormErrorDisplay> = ({
    formErrors,
    path,
    className = '',
    style,
}) => {
    if (!formErrors || !formErrors.length) return <></>
    const thisError = formErrors.find(
        (error: ValidationError) => error.path === path
    )
    if (!thisError) return <></>
    return (
        <FormErrorMessage
            data-testid={'err_' + path}
            className={className}
            style={style ?? { marginBottom: 15 }}
        >
            {thisError.message}
        </FormErrorMessage>
    )
}
