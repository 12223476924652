import { keyframes, styled } from '@/src/stitches.config'
import * as LabelPrimitive from '@radix-ui/react-label'
import NumberFormat from 'react-number-format'

const FieldWrapper = styled('div', {
    position: 'relative',
})

const TextInput = styled('input', {
    zIndex: 0,
    all: 'unset',
    display: 'block',
    border: '2px solid $darkest',
    borderRadius: '$2',
    backgroundColor: '$white',
    padding: '$5 $2',
    height: 35,
    fontSize: 16,
    lineHeight: 1,
    width: '100%',
    maxWidth: '100%',
    boxSizing: 'border-box',
    color: '$darkest',
    variants: {
        scheme: {
            error: {
                border: '2px solid $error',
            },
        },
    },
    '&:focus': {
        borderColor: '$primary',
    },
})

const fadeIn = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
})

const StyledLabel = styled(LabelPrimitive.Root, {
    zIndex: 1,
    padding: '0 0.3em',
    fontSize: '0.75em',
    top: '-0.6em',
    left: '0.9em',
    userSelect: 'none',
    position: 'absolute',
    backgroundColor: '$white',
    color: '$dark',
    animation: `${fadeIn} 200ms`,
    variants: {
        hidden: {
            true: {
                display: 'none',
            },
        },
    },
})

// TODO Set up TS interface

export const TextInputWithLabel = (props: any) => {
    const {
        initialError,
        initialTouched,
        initialValue,
        touched,
        numFormat,
        value,
        label,
        placeholder,
        hasError,
        formErrors,
        ...theRest
    } = props

    return (
        <FieldWrapper>
            {numFormat ? (
                <NumberFormat
                    format={numFormat}
                    customInput={TextInput}
                    value={value}
                    placeholder={placeholder ?? label}
                    scheme={hasError ? 'error' : null}
                    {...theRest}
                />
            ) : (
                <TextInput
                    value={value}
                    placeholder={placeholder ?? label}
                    scheme={hasError ? 'error' : null}
                    {...theRest}
                />
            )}
            <StyledLabel
                role="textbox"
                aria-label={`${label} input field`}
                htmlFor={props.name}
                css={{ marginRight: 15 }}
                // @ts-ignore
                hidden={!value && !placeholder}
            >
                {label}
            </StyledLabel>
        </FieldWrapper>
    )
}
